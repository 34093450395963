// Sticky navigation
  if ( $("#sticky").length ) {
    new Waypoint.Sticky({
      element: $('#sticky')[0]
    });
  }


// Slider
  if ( $('.bxslider').length ) {
    $('.bxslider').bxSlider({
      controls: true,
      auto: true,
      loop: true,
      speed: 700,
      pause: 6000,
      touchEnabled: true
    });
  }

  if ( $('.bxslider-referenzen').length ) {
    $('.bxslider-referenzen').bxSlider({
      controls: true,
      pager: false,
      auto: false,
      loop: true,
      speed: 700,
      touchEnabled: true,
    });
  }


// Accordion
  if ( $('.accordion').length ) {

    if ( $('.accordion-element').hasClass('active') ) {
      $('.accordion-element.active .accordion-content').slideToggle();
    }

    $('.accordion-element-button').on('click', function() {
      const parent = $(this).parent();

      if ( parent.hasClass('active') ) {
        parent.removeClass('active');
        $('.accordion-content', parent).slideUp();
      } else {
        if ( $('.accordion-element').hasClass('active') ) {
          $('.accordion-element').removeClass('active');
          $('.accordion-content').slideUp();
        }

        parent.addClass('active');
        $('.accordion-content', parent).slideToggle();
      }
    });
  }


// Waypoints
  $('#wp-cognito').waypoint(function(direction) {
    if (direction === 'down') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-cognito').addClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '88'
  });
  $('#wp-cognito-end').waypoint(function(direction) {
    if (direction === 'up') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-cognito').addClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '100'
  });
  $('#wp-gedankenspiele').waypoint(function(direction) {
    if (direction === 'down') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-gedankenspiele').addClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-wege').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '88'
  });
  $('#wp-gedankenspiele-end').waypoint(function(direction) {
    if (direction === 'up') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-gedankenspiele').addClass('active');
      $('a.nav-wege').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '100'
  });
  $('#wp-wege').waypoint(function(direction) {
    if (direction === 'down') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-wege').addClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '88'
  });
  $('#wp-wege-end').waypoint(function(direction) {
    if (direction === 'up') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-wege').addClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '100'
  });
  $('#wp-wirkung').waypoint(function(direction) {
    if (direction === 'down') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-wirkung').addClass('active');
      $('a.nav-wege').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-kontakt').removeClass('active');
    }
  }, {
    offset: '88'
  });
  $('#wp-wirkung-end').waypoint(function(direction) {
    if (direction === 'up') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-wirkung').addClass('active');
      $('a.nav-kontakt').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
    }
  }, {
    offset: '100'
  });
  $('#wp-kontakt').waypoint(function(direction) {
    if (direction === 'down') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-kontakt').addClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
    }
  }, {
    offset: '88'
  });

  $('#wp-referenzen').waypoint(function(direction) {
    if (direction === 'down') {
      $('a.nav-referenzen').addClass('active');
      $('a.nav-kontakt').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
    }
  }, {
    offset: '88'
  });

  $('#wp-kontakt-end').waypoint(function(direction) {
    if (direction === 'up') {
      $('a.nav-referenzen').removeClass('active');
      $('a.nav-kontakt').addClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
    }
  }, {
    offset: '100'
  });

  $('#wp-referenzen-end').waypoint(function(direction) {
    if (direction === 'up') {
      $('a.nav-referenzen').addClass('active');
      $('a.nav-kontakt').removeClass('active');
      $('a.nav-wirkung').removeClass('active');
      $('a.nav-cognito').removeClass('active');
      $('a.nav-gedankenspiele').removeClass('active');
      $('a.nav-wege').removeClass('active');
    }
  }, {
    offset: '100'
  });
  $('#wp-top').waypoint(function() {
    $('a.nav-referenzen').removeClass('active');
    $('a.nav-kontakt').removeClass('active');
    $('a.nav-cognito').removeClass('active');
    $('a.nav-gedankenspiele').removeClass('active');
    $('a.nav-wege').removeClass('active');
    $('a.nav-wirkung').removeClass('active');
  });
  $('#wp-bottom').waypoint(function() {
    $('a.nav-referenzen').removeClass('active');
    $('a.nav-kontakt').removeClass('active');
    $('a.nav-cognito').removeClass('active');
    $('a.nav-gedankenspiele').removeClass('active');
    $('a.nav-wege').removeClass('active');
    $('a.nav-wirkung').removeClass('active');
  });


// Content toggle
  $('#show_changeberatung').click(function() {
    $('#changeberatung').fadeIn('slow');
    $('#uebersicht').fadeOut('slow');
  });
  $('#hide_changeberatung').click(function() {
    $('#changeberatung').fadeOut('slow');
    $('#uebersicht').fadeIn('slow');
  });
  $('#show_teamtraining').click(function() {
    $('#teamtraining').fadeIn('slow');
    $('#uebersicht').fadeOut('slow');
  });
  $('#hide_teamtraining').click(function() {
    $('#teamtraining').fadeOut('slow');
    $('#uebersicht').fadeIn('slow');
  });
  $('#show_coaching').click(function() {
    $('#coaching').fadeIn('slow');
    $('#uebersicht').fadeOut('slow');
  });
  $('#hide_coaching').click(function() {
    $('#coaching').fadeOut('slow');
    $('#uebersicht').fadeIn('slow');
  });


// Smoothscroll
  $(function() {
    $('a[href*=#]:not([href=#])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top - 86
          }, 700);
          return false;
        }
      }
    });
  });


// Hide footer
  if ($('body').hasClass('hide-footer')) {
    $('.footer').hide() ;
  }


// Social Share Links
  if ($('.sharing-buttons').length) {

    if ($(window).width() > 1180) {
      new Waypoint.Sticky({
        element: $('.sharing-buttons')[0],
        offset: '130',
        stuckClass: 'fixed'
      });

      var height = $('.sharing-buttons').outerHeight();

      $('.footer').waypoint(function (direction) {
        $('.sharing-buttons').toggleClass('fixed', direction === 'up')
      }, {
          offset: height
        });

      $('.footer').waypoint(function (direction) {
        $('.sharing-buttons').toggleClass('fixed-to-bottom', direction === 'down')
      }, {
          offset: height + 181
        });
    }
  }